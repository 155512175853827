import React, { useEffect, useState } from "react"
import "./buyHomeForm.scss"
import { useStaticQuery, graphql } from "gatsby"
import GravityFormForm from "gatsby-plugin-gravity-forms"
import BuyHomeBg from "../../images/2-people-image.jpeg"
import {
  BuyHomeStep1,
  BuyHomeStep2,
  BuyHomeStep3,
  BuyHomeStep4,
} from "../../validation/validation"
function BuyHomeForm() {
  const data = useStaticQuery(graphql`
    query {
      wpGfForm(databaseId: { eq: 4 }) {
        ...GravityFormFields
      }
    }
  `)

  const [step, setStep] = useState(1)
  useEffect(() => {
    const inputs = document.querySelectorAll(".gravityform__field")
    const step1 = document.querySelectorAll(".gravityform__field.step-1")
    const step2 = document.querySelectorAll(".gravityform__field.step-2")
    const step3 = document.querySelectorAll(".gravityform__field.step-3")
    const step4 = document.querySelectorAll(".gravityform__field.step-4")
    const step5 = document.querySelectorAll(".gravityform__field.step-5")
    const submitBtn = document.querySelector(".gravityform__button")

    Array.from(inputs).forEach(input => {
      input.classList.remove("display")
    })
    submitBtn.classList.remove("display")
    if (step == 1) {
      Array.from(step1).forEach(step => {
        step.classList.add("display")
      })
    }
    if (step == 2) {
      Array.from(step2).forEach(step => {
        step.classList.add("display")
        let phone = step.querySelector(".ginput_container_phone input")
        let next = document.querySelector('.next-button')

        if (phone) {
          next.setAttribute('dsiabled', 'disabled')
          phone.setAttribute("type", "number")
          phone.addEventListener('keyup', function(e) {
            e.currentTarget.value = e.currentTarget.value.replace(/[^\d]/,'').substring(0, 10)
          })
        }
      })
    }
    if (step == 3) {
      Array.from(step3).forEach(step => {
        step.classList.add("display")
      })
    }
    if (step == 4) {
      Array.from(step4).forEach(step => {
        step.classList.add("display")
      })
    }
    if (step == 5) {
      Array.from(step5).forEach(step => {
        step.classList.add("display")
      })
      submitBtn.classList.add("display")
    }
  }, [step])
  // handle next step
  const next = () => {
    // inputs element
    const step1 = document.querySelectorAll(
      ".gravityform__field.field-required.step-1 input"
    )

    const step2 = document.querySelectorAll(
      ".gravityform__field.field-required.step-2 input"
    )
    const step3 = document.querySelectorAll(
      ".gravityform__field.field-required.step-3 input"
    )
    const step4 = document.querySelectorAll(
      ".gravityform__field.field-required.step-4 input"
    )
    // validation element
    const validateStep1 = document.querySelectorAll(
      ".gravityform__field.step-1 .validation_message"
    )
    const validateStep2 = document.querySelector(
      ".gravityform__field.step-2 .validation_message"
    )
    const validateStep3 = document.querySelectorAll(
      ".gravityform__field.step-3 .validation_message"
    )
    const validateStep4 = document.querySelectorAll(
      ".gravityform__field.step-4 .validation_message"
    )

    step === 1 && BuyHomeStep1(Array.from(step1), validateStep1, setStep, step)
    step === 2 && BuyHomeStep2(Array.from(step2), validateStep2, setStep, step)
    step === 3 && BuyHomeStep3(Array.from(step3), validateStep3, setStep, step)
    step === 4 && BuyHomeStep4(Array.from(step4), validateStep4, setStep, step)
  }
  const prev = () => {
    setStep(step - 1)
  }
  return (
    <div className="form-section buyhome-form">
      <div
        className="header-section"
        style={{ backgroundImage: `url(${BuyHomeBg})` }}
      >
        <div className="inner-content">
          <h2>BUYING A HOME?</h2>
          <p>
            The home buying journey is about more than four walls and a roof.
            There's a story behind every mortgage. As a local mortgage company,
            that's a fact we don't take for granted. We're here to help you find
            a mortgage that moves you in the right direction.
          </p>
        </div>
      </div>
      <div className="multi-step-form ">
        <div className="form-header">
          <h2>GET A COMPLIMENTARY RATE QUOTE</h2>
          <div className="progress-bar">
            <div
              className="bar-percentage"
              style={{ width: (step / 5) * 100 + "%" }}
            ></div>
          </div>
          <p className="progress-title">Step: {step} OF 5</p>
        </div>

        <div className={`step-wrapper step-${step}`}>
          <GravityFormForm data={data} />
        </div>
        {step < 5 ? <Next next={next} /> : <></>}
        {step >= 2 ? <Prev prev={prev} /> : <></>}
      </div>
    </div>
  )
}

function Next({ next }) {
  return (
    <div className="handle-button" onClick={next}>
      <button className="next-button">Next</button>
    </div>
  )
}
function Prev({ prev }) {
  return (
    <div onClick={prev} className="handle-button prev">
      <button className="prev-button">Previous</button>
    </div>
  )
}

export default BuyHomeForm
